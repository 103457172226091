'use client'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import EmailIcon from '@mui/icons-material/Email'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { Grid } from '@mui/material'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import logo from 'src/assets/webp/logo2.webp'
import { useDialogData } from 'src/context/DialogContext'
import { getAllGhostPosts } from 'src/utils/GhostAPI'
import { KGRchargebacks } from 'src/utils/case-study'

function sliceStringKeepLastWord(inputString: string, maxLength: number) {
  if (inputString.length <= maxLength) {
    return inputString
  }

  let lastSpaceIndex = inputString.lastIndexOf(' ', maxLength)

  if (lastSpaceIndex === -1) {
    lastSpaceIndex = inputString.indexOf(' ', maxLength)
  }

  if (lastSpaceIndex !== -1) {
    return inputString.slice(0, lastSpaceIndex) + '...'
  }

  return inputString.slice(0, maxLength)
}

export default function Footer() {
  const { openDialog } = useDialogData()
  const [location, setLocation] = useState('')
  const [blogs, setBlogs] = useState<any>([])

  useEffect(() => {
    const getPosts = async () => {
      let appPosts = await getAllGhostPosts()
      setBlogs(appPosts.posts)
    }

    getPosts()
  }, [])

  useEffect(() => {
    if (window) setLocation(window.location.origin)
  }, [])

  const Card = ({
    icon,
    title,
    subtitle,
    img,
    bgcolor,
    link,
    width
  }: {
    bgcolor: string | undefined
    icon: StaticImageData | string
    title: string | undefined
    subtitle: string | undefined
    img: StaticImageData | string
    link: string | undefined
    width?: string | undefined
  }) => (
    <Link href={`/case-study/${link}`}>
      <div
        style={{ background: bgcolor, height: '100%' }}
        className={`rounded-lg bg-[${bgcolor}] overflow-hidden flex flex-col justify-between`}>
        <div className="p-5">
          <Image src={icon} alt="store availability" width={15} height={15} />
          <h6 className="text-[10px] text-black font-normal my-2">{title}</h6>
          <h2 className="text-[13px] text-black font-semibold">{subtitle}</h2>
        </div>
        <div
          className={`${width ? `w-[${width}]` : 'w-[90%]'} h-[100px] ml-auto`}>
          <div className="relative w-full h-full">
            <Image
              className=" object-cover"
              style={{
                objectPosition: '0px 0px'
              }}
              fill
              alt="store availability"
              src={img}
            />
          </div>
        </div>
      </div>
    </Link>
  )

  return (
    <div className="bg-[#122937] py-16 relative w-full flex justify-center">
      <div className="sm:w-[90%] w-[80%] text-white">
        <div className="mb-5">
          <Link href="/" className="inline-block">
            <Image src={logo} alt="logo" className="sm:h-12 h-10 w-auto" />
          </Link>
        </div>
        <Grid container columnSpacing={4} rowSpacing={6} className="">
          <Grid item lg={3} md={12} className="">
            <div className="container">
              <div className="">
                <h6 className="sm:text-[22px] text-[18px] font-[400] mb-5">
                  Make intelligent decisions using data through Loop
                </h6>
                <button
                  className="bg-[#DF5F2E] text-white font-medium sm:py-3 sm:px-5 py-2 px-4 rounded-md transition focus:outline-none sm:text-[14px] text-[12px]"
                  onClick={openDialog}>
                  TRY IT NOW
                </button>
              </div>
            </div>
          </Grid>
          <Grid item lg={1} className="xl:block hidden"></Grid>
          <Grid item lg={8} md={12} sm={12}>
            <Grid container columnSpacing={4} rowSpacing={6}>
              <Grid item lg={2} md={2} sm={5} xs={12}>
                <h6 className="uppercase text-[14px] font-semibold tracking-widest mb-3">
                  Pages
                </h6>
                <div className="flex flex-col">
                  <Link
                    href="/"
                    className="text-[13px] font-[400] text-[#ffffffbb] hover:text-[#ffffff] mb-2 self-start">
                    Home
                  </Link>
                  <Link
                    href="/privacy-policy"
                    className="text-[13px] font-[400] text-[#ffffffbb] hover:text-[#ffffff] mb-2 self-start">
                    Privacy
                  </Link>
                  <Link
                    href="/terms-of-service"
                    className="text-[13px] font-[400] text-[#ffffffbb] hover:text-[#ffffff] mb-2 self-start">
                    Terms of Service
                  </Link>
                  <Link
                    href="https://app.dover.io/Loop%20AI/careers/372c60ef-0c09-4553-8255-d78deca18cb9"
                    target="__blank"
                    className="text-[13px] font-[400] text-[#ffffffbb] hover:text-[#ffffff] self-start">
                    Careers
                  </Link>
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={7} xs={12}>
                <h6 className="uppercase text-[14px] font-semibold tracking-widest mb-3">
                  News
                </h6>
                {blogs && blogs.length > 0 ? (
                  <div className="flex flex-col">
                    {blogs.slice(0, 3).map((blog: any, index: number) => (
                      <div key={index}>
                        <Link
                          href={
                            blog.external || blog.media
                              ? blog.url
                              : `/blog/${blog.slug}`
                          }
                          className="text-[13px] font-[400] text-[#ffffffbb] hover:text-[#ffffff] self-start flex items-center"
                          target={blog.external || blog.media ? '_blank' : ''}>
                          {sliceStringKeepLastWord(blog.title, 60)}
                        </Link>
                        {index !== blogs.slice(0, 3).length - 1 && (
                          <div className="w-[40%] h-[2px] bg-[#F8F8F8] my-4 opacity-[10%]" />
                        )}
                      </div>
                    ))}
                    <Link href="/blog" className="mt-4">
                      <span className="text-[13px] font-[400] self-start mr-1">
                        View All News
                      </span>
                      <ArrowForwardIcon sx={{ fontSize: '20px' }} />
                    </Link>
                  </div>
                ) : (
                  <Image
                    src="/img/blogs/Loading.svg"
                    alt="Loader"
                    width={40}
                    height={40}
                  />
                )}
              </Grid>
              <Grid item lg={3} md={3} sm={5} xs={12}>
                <h6 className="uppercase text-[14px] font-semibold tracking-widest mb-3">
                  Case Studies
                </h6>
                <div className="mb-4">
                  <Card
                    icon={KGRchargebacks.logo}
                    title={KGRchargebacks.title1}
                    subtitle={KGRchargebacks.title2}
                    img={KGRchargebacks.heroImg}
                    bgcolor={KGRchargebacks.bgColor}
                    link="rallys-az-reduces-order-errors-and-chargebacks-by-60-using-loop"
                  />
                </div>
                <Link href="/case-study">
                  <span className="text-[13px] font-[400] self-start mr-1">
                    View All Case Studies
                  </span>
                  <ArrowForwardIcon sx={{ fontSize: '20px' }} />
                </Link>
              </Grid>
              <Grid item lg={3} md={3} sm={7} xs={12}>
                <h6 className="uppercase text-[14px] font-semibold tracking-widest mb-3">
                  Contact
                </h6>
                <div className="text-[13px] leading-6 font-[400] text-[#ffffffbb] gap-[5px] flex flex-col">
                  <p>Full Meals Technology Inc.</p>
                  <p>505 Montgomery Street,</p>
                  <p>11th Floor,</p>
                  <p>San Francisco, CA,</p>
                  <p>94111 USA</p>
                </div>
                <div className="mt-4 flex">
                  <Link
                    href="https://www.linkedin.com/company/loopaixyz/"
                    target="__blank"
                    className="mr-2 text-[#ffffffbb] hover:text-[#ffffff]">
                    <LinkedInIcon />
                  </Link>
                  <a href="mailto:support@tryloop.ai">
                    <EmailIcon className="text-[#ffffffbb] hover:text-[#ffffff]" />
                  </a>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <p className="text-[#FFFFFFA3] text-sm">
              ©2022 All Rights Reserved. LOOP.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
