import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import cx from 'classnames'
import Link from 'next/link'
export interface NavLinkProps {
  isShow?: boolean
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  iconBgColor: string
  iconColor: string
  href: string
  title: string
  subTitle?: string
}

export default function NavLink(props: NavLinkProps) {
  const { Icon } = props
  return (
    <Link href={props.href} className="inline-block w-fit">
      <div className="flex flex-row items-center gap-3 cursor-pointer transition-all p-2">
        <div
          className={cx(
            `flex items-center justify-center w-7 h-7 rounded text-xs bg-${props.iconBgColor} text-${props.iconColor}`
          )}
          style={{
            background: props.iconBgColor,
          }}
        >
          <Icon fontSize="inherit" color="inherit" />
        </div>

        <div className="flex-1">
          <p className="text-xs font-semibold">{props.title}</p>
          {props.subTitle && (
            <p className="text-xs font-light opacity-50 mt-0.5">
              {props.subTitle}
            </p>
          )}
        </div>
      </div>
    </Link>
  )
}
