'use client'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { usePathname } from 'next/navigation'
import React from 'react'
import navData from 'src/utils/nav-data'
import NavLink from './NavLink'
// import chargebackLogo from 'src/assets/tools/chargebacks.png'

export default function DropdownComponent({
  mobile = false,
  close,
  isSelect,
  setIsSelect
}: {
  mobile?: boolean
  close?: Function
  isSelect?: string
  setIsSelect: Function
}) {
  const [open, setOpen] = React.useState(false)
  const [isNavData, setIsNavData] = React.useState(false)
  const path = usePathname()

  React.useEffect(() => {
    function closeDropdown() {
      setOpen(false)
    }
    window.addEventListener('click', closeDropdown)
    return () => {
      window.removeEventListener('click', closeDropdown)
    }
  }, [open])

  React.useEffect(() => {
    let flag = false

    navData.map((nav: any) => {
      if (nav.href === path) {
        flag = true
        setIsSelect(nav.title)
      }
    })

    if (flag) setIsNavData(true)
    else {
      setIsNavData(false)
      if (path === '/' || path === 'home') setIsSelect('home')
      else if (path.includes('case-study')) setIsSelect('case-study')
      else if (path.includes('blog')) setIsSelect('blog')
      else setIsSelect('')
    }
  }, [path])

  return (
    <>
      <div
        className={`relative ${mobile ? 'px-[6px]' : ''} ${
          open && mobile ? 'pt-[6px]' : ''
        }`}
        style={{
          borderRadius: '12px 12px 0px 0px',
          background: open && mobile ? 'rgba(216, 216, 216, 0.38)' : 'white'
        }}>
        <button
          className={`${
            mobile
              ? 'w-full text-xl font-[400] justify-between px-[25px] py-3'
              : 'text-xs font-semibold ml-2 p-3 px-4 gap-3'
          } rounded-md  leading-8 border text-[#6F6F6F] bg-white hover:bg-zinc-100 transition-all duration-300 ease-in-out flex flex-row items-center
          ${isNavData ? 'border-[#6F6F6F]' : 'border-[#E7E7E7]'}`}
          onClick={(e) => {
            e.stopPropagation()
            setOpen((prev) => !prev)
          }}>
          <span>
            Products
            {isNavData && ` | ${isSelect}`}
          </span>
          <div className="flex">
            <KeyboardArrowDownIcon color="inherit" fontSize="inherit" />
          </div>
        </button>
        {open && (
          <div
            className={`${
              mobile ? 'w-full' : 'w-max backdrop-blur-sm mt-[20px]'
            } shadow  z-10  top-full absolute left-0`}
            style={{
              borderRadius: mobile ? '0px 0px 12px 12px' : '12px',
              background: 'rgba(216, 216, 216, 0.38)'
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}>
            <div
              className={`grid grid-cols-1  ${
                mobile
                  ? 'w-full p-2 gap-3'
                  : ' m-2 mt-[0.60rem] mr-[0.60rem] p-3 md:p-4 md:px-1.5rem flex flex-col gap-2'
              }`}
              style={{
                background: mobile ? 'rgba(216, 216, 216, 0.38)' : 'white',
                borderRadius: mobile ? '0px 0px 12px 12px' : '12px'
              }}>
              {navData.map((prop) => {
                return (
                  prop.isShow && (
                    <div
                      className={`${
                        mobile ? 'p-2' : ''
                      } hover:bg-zinc-200 bg-white rounded-md transition-all duration-300 ease-in  md:flex`}
                      style={{
                        border: mobile ? '1px solid rgba(231, 231, 231, 1)' : ''
                      }}
                      key={prop.href}
                      onClick={() => {
                        setOpen(false)
                        setIsSelect(prop.title)
                        if (close) close()
                      }}>
                      <NavLink {...prop} />
                      <div className="md:w-8 md:h-8" />
                    </div>
                  )
                )
              })}
            </div>
          </div>
        )}
      </div>
      {/* <Dropdown
      label={<p className="text-xs font-semibold">Products</p>}
      // label="Products"
      style={{
        fontSize: '18px',
        background: 'white',
        color: '#6F6F6F',
        border: '1px solid #E7E7E7',
        borderRadius: 6,
        padding: 4,
      }}
    >
      <div className="p-0 bg-[#D8D8D8] rounded-lg">
        <div
          className={`bg-white p-3 grid gap-5 ${
            navData.length < 2 ? 'grid-cols-1' : 'grid-cols-2'
          }`}
        >
          {navData.map((navProps) => {
            return (
              <div key={navProps.href}>
                <NavLink {...navProps} />
              </div>
            )
          })}
        </div>
      </div>
    </Dropdown> */}
    </>
  )
}
