'use client'

import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

export default function AnnouncementBanner() {
  const { height } = useWindowSize()
  const [showConfetti, setShowConfetti] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  const confettiWidth = useMemo(() => {
    if (typeof window === 'undefined') return 0
    return window.innerWidth
  }, [])

  return (
    <div className="relative w-full">
      {confettiWidth > 0 && (
        <Confetti
          width={confettiWidth}
          height={height}
          recycle={showConfetti}
        />
      )}

      <div className="bg-[#122937] p-4 md:py-8 w-full flex items-center justify-center">
        <Link
          href="/blog/loop-raises-6m-to-champion-and-unlock-third-party-delivery-profitability-for-restaurants"
          className="text-white text-[16px] text-center hover:underline">
          LOOP Raises <span className="text-[#df5f2e]">$6M</span> to Champion
          and Unlock Third-Party Delivery Profitability for Restaurants
        </Link>
      </div>
    </div>
  )
}
