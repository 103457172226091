import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import { NavLinkProps } from 'src/components/Dropdown/NavLink'

const navData: NavLinkProps[] = [
  {
    isShow: false,
    href: '/chargebacks',
    title: 'Chargebacks',
    subTitle: 'Understand your 3P finances',
    Icon: CurrencyExchangeIcon,
    iconBgColor: 'rgba(255, 183, 74, 0.2)',
    iconColor: 'rgba(28, 27, 31, 1)',
  },
  {
    isShow: true,
    href: '/store-availability',
    title: 'Store Availability',
    subTitle: 'Manage store uptime',
    Icon: WorkHistoryIcon,
    iconBgColor: 'rgba(255, 183, 74, 0.2)',
    iconColor: 'rgba(28, 27, 31, 1)',
  },
  {
    isShow: true,
    Icon: CampaignRoundedIcon,
    iconBgColor: 'rgba(125, 168, 255, 0.2)',
    iconColor: 'rgba(28, 27, 31, 1)',
    href: '/marketing',
    title: 'Marketing',
    subTitle: 'Detailed insights for Marketing',
  },
  {
    isShow: true,
    href: '/finance',
    title: 'Delivery accounting reconciliation',
    subTitle: 'Financial intelligence for delivery revenue',
    Icon: MonetizationOnRoundedIcon,
    iconBgColor: 'rgba(23, 238, 112, 0.2)',
    iconColor: 'rgba(28, 27, 31, 1)',
  },
  {
    isShow: true,
    Icon: AssessmentRoundedIcon,
    iconBgColor: 'rgba(97, 205, 187, 0.2)',
    iconColor: 'rgba(28, 27, 31, 1)',
    href: '/weekly-performance-report',
    title: 'Store Performance',
    subTitle: 'Drive a culture of accountability',
  },
]

export default navData
