'use client'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { Next13ProgressBar } from 'next13-progressbar'
import { Fragment, Suspense, useEffect } from 'react'
import AnnouncementBanner from 'src/components/AnnouncementBanner'
import Appbar from 'src/components/Appbar/Appbar.component'
import Footer from 'src/components/Footer/Footer.component'

const DialogComp = dynamic(() => import('src/components/Dialog'), {
  ssr: false
})

interface iLayout {
  children: React.ReactNode
}

const Body = ({ children }: iLayout) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search)

      // Store specific parameters in localStorage
      const paramsToStore = ['ref', 'utm_source']
      let referrerValue = ''

      paramsToStore.forEach((param) => {
        const value = searchParams.get(param)
        if (value) {
          referrerValue = value
        }
      })

      // Giving most preferrence to document.referrer
      if (document.referrer) referrerValue = document.referrer

      if (referrerValue) {
        localStorage.setItem('referrer', referrerValue)
      }
    }
  }, [window])

  return (
    <Fragment>
      <div id="root">
        <Suspense fallback={null}>
          <Next13ProgressBar
            color="#196E82"
            height="4px"
            options={{ showSpinner: false }}
          />
          <AnnouncementBanner />
        </Suspense>
        <DialogComp />

        <Appbar />

        <main>{children}</main>

        <Footer />
      </div>
      <Script
        src="https://guidebar-backend-727ab3a68ba9.herokuapp.com/install/tryloop_ai"
        defer={true}
      />
    </Fragment>
  )
}
export default Body
